<template>
  <v-container class="content ma-0 pa-4 password-container">
    <v-card mb-3 class="password-card">
      <v-text-field
        v-model="oldPassword"
        light
        prepend-inner-icon="mdi-key"
        :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="oldPasswordShow ? 'text' : 'password'"
        name="oldPassword"
        label="Insira sua senha atual"
        counter
        @click:append="oldPasswordShow = !oldPasswordShow"
      />
      <v-text-field
        v-model="password"
        light
        prepend-inner-icon="mdi-key"
        :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="passwordShow ? 'text' : 'password'"
        name="password"
        label="Nova Senha"
        counter
        @click:append="passwordShow = !passwordShow"
        @keyup="checkPassword($event)"
      />
      <v-text-field
        v-model="confirmPassword"
        light
        prepend-inner-icon="mdi-key"
        :append-icon="confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="confirmPasswordShow ? 'text' : 'password'"
        name="confirmPassword"
        label="Confirme sua nova senha"
        counter
        @click:append="confirmPasswordShow = !confirmPasswordShow"
        @keyup="checkPassword($event)"
      />
      <v-alert
        v-if="showPasswordMatchMessageError"
        small
        outlined
        type="error"
        prominent
        border="left"
      >
        {{ showPasswordMatchMessageError }}
      </v-alert>
      <div class="mb-3 justify-center">
        <v-btn
          :disabled="disableButton"
          color="success"
          @click="updatePassword"
        >
          Atualizar
        </v-btn>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      multi-line
      top
      :timeout="13500"
      :color="snackbarMessageType"
    >
      {{ snackbarMessage }}
      <v-btn dark text @click="snackbar = false"> Fechar </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardN',
  components: {},
  data() {
    return {
      oldPassword: '',
      oldPasswordShow: false,

      password: '',
      passwordShow: false,

      confirmPassword: '',
      confirmPasswordShow: false,

      disableButton: true,

      showPasswordMatchMessageError: '',

      snackbar: false,
      snackbarMessage: '',
      snackbarMessageType: 'error',

      rules: {
        required: value => !!value || 'Obrigatório.',
        emailMatch: () => 'O email ou a senha estão incorretos',
        min: value => value.length >= 6 || 'Mínimo de 6 caracteres',
      },
    }
  },

  methods: {
    checkPassword() {
      if (!this.password || !this.confirmPassword) {
        return
      }

      if (this.password !== this.confirmPassword) {
        this.showPasswordMatchMessageError = 'As senhas não coincidem!'
        this.disableButton = true
      } else {
        this.showPasswordMatchMessageError = ''
        this.disableButton = false
      }
    },

    async updatePassword() {
      const email = this.$user.get().email

      this.api.auth
        .updatePassword(
          email,
          this.oldPassword,
          this.password,
          this.confirmPassword,
        )
        .then(() => {
          this.snackbarMessage = 'Senha atualizada com sucesso!'
          this.snackbar = true
          this.snackbarMessageType = 'success'

          setTimeout(async () => {
            await this.$router.push({ path: 'dashboard' })
          }, 2000)
        })
        .catch(error => {
          this.snackbarMessage = error.message
            ? error.message
            : 'Não foi possível atulizar sua senha!'
          this.snackbar = true
          this.snackbarMessageType = 'error'
        })
    },
  },
}
</script>

<style>
.password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.password-card {
  padding: 2rem;
  width: 60%;
}
</style>
